.rdp-months .rdp-caption_label {
  font-size: 0.88rem;
  font-weight: 500;
}

.rdp-months .rdp-head_cell {
  text-transform: capitalize;
  font-size: 0.7rem;
  color: #777777;
  font-weight: normal;
}

.rdp.rdp {
  --rdp-cell-size: 40px;
  margin: 0;
}

@media (min-width: 768px) {
  .rdp.rdp {
    --rdp-cell-size: 50px;
  }

  .rdp-months .rdp-caption_label {
    font-size: 1rem;
    font-weight: 500;
  }

  .rdp-months .rdp-head_cell {
    text-transform: capitalize;
    font-size: 0.8rem;
    color: #777777;
    font-weight: normal;
  }
}

.rdp-months .rdp-cell {
  font-size: 0.88rem;
  color: #777777;
  padding: 0 2px;
}

.rdp-months .rdp-day_today {
  background: rgba(0, 0, 0, 0.05);
  font-weight: "normal";
}

.rdp-months .rdp-day_selected:not([aria-disabled="true"]),
.rdp-months .rdp-day_selected:focus:not([aria-disabled="true"]),
.rdp-months .rdp-day_selected:active:not([aria-disabled="true"]),
.rdp-months .rdp-day_selected:hover:not([aria-disabled="true"]) {
  color: white;
  background-color: #235082;
}

.rdp-months .rdp-day_selected:focus:not([aria-disabled="true"]),
.rdp-months .rdp-button:active,
.rdp-months .rdp-button:focus {
  border: 0 none;
}

.rdp .rdp-day_outside {
  opacity: 1;
}
