/* src/loading/animation.css */
@keyframes spinner {
  to {
    transform: rotate(1turn);
  }
}

/* src/toast/animation.css */
@keyframes slideUp {
  from {
    transform: translate3d(0, calc(100% + 24px), 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideDown {
  from {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  to {
    transform: translate3d(0, calc(100% - 10px), 0);
    opacity: 0;
  }
}
@keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes swipeOut {
  from {
    transform: translate3d(0, var(--radix-toast-swipe-end-y), 0);
  }
  to {
    transform: translate3d(0, calc(100% + 24px), 0);
  }
}
.toast-animation {
  animation: slideUp 150ms cubic-bezier(0.16, 1, 0.3, 1);
}
.toast-animation[data-swipe=move] {
  transform: translate3d(0, var(--radix-toast-swipe-move-y), 0);
}
.toast-animation[data-swipe=cancel] {
  transform: translate3d(0, 0, 0);
  transition: transform 200ms ease-out;
}
.toast-animation[data-state=closed] {
  animation: slideDown 100ms ease-in forwards;
}
.toast-animation[data-swipe=end] {
  animation: swipeOut 100ms ease-out forwards;
}
