@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  font-size: 16px;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: Inter, Helvetica Neue, Arial, sans-serif;
}

html,
body,
body > div:first-child,
div#__next {
  /* This ensures that the innermost div element generated by next.js is set to full height. */
  height: 100%;
}

input:focus,
textarea:focus,
select:focus {
  outline: #194128 auto 5px;
}

button[disabled] {
  cursor: default;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #737373 !important;
  opacity: 1; /* Firefox */
}

.select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M1.17167 0.878683L0.464574 1.58578L4.00007 5.12134L7.53564 1.58578L6.82854 0.878683L4.00007 3.70713L1.17167 0.878683Z" fill="currentColor"/%3E%3C/svg%3E%0A');
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.firebase-emulator-warning {
  display: none;
}

/* accordion open/close animation */
.accordion-content {
  overflow: hidden;
}
.accordion-content[data-state="open"] {
  animation: accordionSlideDown 300ms cubic-bezier(0.33, 1, 0.68, 1);
}
.accordion-content[data-state="closed"] {
  animation: accordionSlideUp 300ms cubic-bezier(0.33, 1, 0.68, 1);
}

@keyframes accordionSlideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes accordionSlideUp {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}
